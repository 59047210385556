import AOS from "aos";
import "aos/dist/aos.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import About from "./Pages/About";
import AuthLayout from "./Pages/Auth/AuthLayout";
import Login from "./Pages/Auth/layouts/Login";
import Register from "./Pages/Auth/layouts/Register";
import AllBlogs from "./Pages/blog/AllBlogs";
import SingleBlog from "./Pages/blog/SingleBlog";
import DashboardLayout from "./Pages/Dashboard/DashboardLayout";
import Index from "./Pages/Dashboard/layouts/Index";
import PaymentDetails from "./Pages/Dashboard/layouts/PaymentDetails";
import Payments from "./Pages/Dashboard/layouts/Payments";
import Transaction from "./Pages/Dashboard/layouts/Transaction";
import Faq from "./Pages/Faq";
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import { MobileLoginReroutePage } from "./Pages/MobileLoginReroutePage";
import SuccessPage from "./Pages/SuccessPage";
import RequireAuth from "./Components/RequireAuth";
import Partnership from "./Pages/Partnership";

function App() {
  const location = useLocation();

  // when location changes, scroll to top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  AOS.init();
  return (
    <>
      <Routes>
        {/* WEB PAGES  */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<AllBlogs />} />
        <Route path="/blog/:slug" element={<SingleBlog />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/term-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/auth/login" element={<MobileLoginReroutePage />} />
        <Route path="/success" element={<SuccessPage />} />

        {/* AUTH  */}
        <Route path="" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="get-started" element={<Register />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Index />} />
            <Route path="payments" element={<Payments />} />
            <Route path="payments/:type" element={<PaymentDetails />} />
            <Route path="transactions" element={<Transaction />} />
          </Route>
        </Route>

        {/* DASHBOARD  */}
        <Route
          path="*"
          element={
            <>
              <h1>'404', PAGE NOT FOUND</h1>
              <Link to="/">Go to Home</Link>
            </>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
