import React from "react";

const Team = () => {
  return (
    <section className="container-fluid py-5 my-5 team">
      <div className="container">
        <div className="team-header text-center mb-5">
          <h3>
            Meet our <span className="tx-blue">Team</span>
          </h3>
        </div>

        <div className="col-lg-8 col-xl-6 mx-auto">
          <div className="row g-3">
            <div className="col-md-6 col-sm-8 col-11 mx-lg-0 mx-auto">
              <div>
                <div className="mb-3">
                  <img
                    src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672096970/A1tutor%20website/team1_ny77v1.png"
                    width="100%"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5 className="mb-0">CEO</h5>
                  <p>Olanipekun Kayode Paul</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-8 col-11 mx-lg-0 mx-auto">
              <div>
                <div className="mb-3">
                  <img
                    src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672096971/A1tutor%20website/team2_dcheax.png"
                    width="100%"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5 className="mb-0">Advisor</h5>
                  <p>Ayodeji Niyi-Adesola</p>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6 col-sm-8 col-11 mx-auto">
              <div>
                <div className="mb-3">
                  <img
                    src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672096971/A1tutor%20website/team3_fu8n8n.png"
                    width="100%"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5 className="mb-0">Advisor</h5>
                  <p>Dr Festus Adedoyin</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
