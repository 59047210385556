import React from "react";
import { Link } from "react-router-dom";
import A1tutor from "../assets/images/logo.png";

const Footer = () => {
  return (
    <footer className="container-fluid py-4 footer">
      <div className="container">
        <div className="row gx-3 mb-5">
          <div className="col-lg-6 footer-content">
            <div className="col-lg-9">
              <div>
                <img src={A1tutor} alt="" />
              </div>

              <div className="my-3">
                <p className="info">
                  We provide concise lesson notes, interactive instructional
                  videos, and a personalized learning experience that helps our
                  users quickly understand complex subjects and learn in a fun
                  and memorable way.
                </p>
              </div>

              <div>
                <h6 className="mb-0">Contact Us:</h6>
                {/* <p className="number">+234 912 612 5331</p> */}
                <p className="number mb-1">0912 770 8307</p>
                <p className="number">0912 612 5331</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ms-auto footer-links">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <h6>Company</h6>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Press</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <h6>Legal</h6>
                  <ul>
                    <li>
                      <Link to="/term-of-service">Term of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    {/* <li>
                        <Link to="/">Cookie Policy</Link>
                      </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <p className="copyright">
            COPYRIGHT &copy; {new Date().getFullYear()} A1 TUTORS
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
