import React, { useEffect, useState } from "react";
import WhiteArrow from "../assets/images/button-arrow.png";
import Footer from "../Components/Footer";
import InfoBanner from "../Components/InfoBanner";
import DownloadModal from "../Components/modal/DownloadModal";
import NavBar from "../Components/NavBar";
import IntroTitle from "../Components/shared/IntroTitle";

const Partnership = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const changeSecondBoxStyle = () => {
    if (window.scrollY >= 40) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    changeSecondBoxStyle();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeSecondBoxStyle);
  });
  return (
    <>
      <main
        className="partnership-page container-fluid px-0"
        style={{ overflowX: "hidden" }}
      >
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <div className="partnership-page_header">
          <div className="row">
            <div className="col-lg-12 text-center partnership-page_header_first_box d-flex">
              <div className="container p-lg-5 p-3">
                <IntroTitle>
                  <h1 className="partnership-page_header_first_box_title mt-5">
                    Empowering <br />
                    education{" "}
                    <span
                      className="tx-fraunce"
                      style={{
                        fontStyle: "italic",
                        fontWeight: 300,
                        letterSpacing: "-6px",
                      }}
                    >
                      together!
                    </span>
                  </h1>
                </IntroTitle>
              </div>
            </div>
            <div className="col-lg-12 partnership-page_header_second_box">
              <div
                className={`partnership-page_header_second_box_text_box p-md-5 p-3 ${
                  isScrolled ? "active" : ""
                }`}
                style={{ minHeight: "200px" }}
              >
                <div className="text-lg-center col-lg-8 mx-auto collaborative-initiative-box py-3">
                  <h5 className="fw-bold mb-md-5 mb-3">
                    Our collaborative learning Initiative
                  </h5>
                  <h3>
                    At A1tutor, we believe that quality education should be
                    accessible to all, regardless of financial constraints. We
                    are thrilled to extend our collaborative efforts to schools,
                    NGOs, private sectors, and education agents, ensuring that
                    our educational technology app becomes readily available for
                    learners from all walks of life.
                  </h3>
                </div>

                <div className="who-can-collaborate col-lg-12 mx-auto pt-0 pt-lg-5 py-5">
                  <h3 className=" mx-auto mb-4 text-center">
                    Who can{" "}
                    <span
                      className="tx-fraunce"
                      style={{
                        fontStyle: "italic",
                        fontWeight: 300,
                        letterSpacing: "-3px",
                      }}
                    >
                      collaborate
                    </span>{" "}
                    <br />
                    with us?
                  </h3>

                  <div className="row g-3">
                    <div className="col-xl-3 col-md-6">
                      <div className="who-can-collaborate-box">
                        <div className="image-box"></div>
                        <div className="content-box">
                          <h5 className="mb-3">Schools</h5>
                          <p>
                            We invite schools of all sizes and academic levels
                            to join hands with A1tutor. By collaborating with
                            us, you can provide your students with a
                            state-of-the-art e-learning platform, enhancing
                            their learning experience and academic performance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="who-can-collaborate-box">
                        <div className="image-box"></div>
                        <div className="content-box">
                          <h5 className="mb-3">NGOs</h5>
                          <p>
                            If your organization is dedicated to promoting
                            education and facilitating learning opportunities
                            for underprivileged communities, we would love to
                            collaborate with you. Together, we can empower
                            learners who lack access to traditional educational
                            resources.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="who-can-collaborate-box">
                        <div className="image-box"></div>
                        <div className="content-box">
                          <h5 className="mb-3">Private Sectors</h5>
                          <p>
                            A1tutor welcomes partnerships with private companies
                            that are keen on investing in education and
                            contributing to the growth of future generations. By
                            joining forces, we can create a positive impact on
                            the education landscape.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <div className="who-can-collaborate-box">
                        <div className="image-box"></div>
                        <div className="content-box">
                          <h5 className="mb-3">Education Agents</h5>
                          <p>
                            Education agents play a crucial role in connecting
                            learners with the right educational resources. We
                            encourage education agents to collaborate with us,
                            enabling their clients to benefit from our
                            interactive and dynamic learning platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="partnership-page_section container-fluid py-5"> */}
                <div className="benefit-of-collaboration mt-5">
                  <div className="">
                    <div className="benefit-of-collaboration-header text-center mb-3 px-0">
                      <h3 className="mb-4">Benefits of collaboration</h3>
                    </div>

                    <div className="benefit-of-collaboration-body px-0">
                      <div className="col-lg-10 mx-auto">
                        <div
                          className="benefit-of-collaboration-body-content-box"
                          style={{ borderTop: "none" }}
                        >
                          <div className="row">
                            <div className="col-lg-5">
                              <h5 className="col-lg-10">Affordability</h5>
                            </div>
                            <div className="col-lg-7">
                              <p>
                                Through collaboration, we aim to make our
                                learning app accessible at an affordable amount,
                                ensuring that financial limitations do not
                                hinder the pursuit of knowledge.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="benefit-of-collaboration-body-content-box">
                          <div className="row">
                            <div className="col-lg-5">
                              <h5 className="col-lg-10">
                                Diverse Learning Resources
                              </h5>
                            </div>
                            <div className="col-lg-7">
                              <p>
                                Collaborators gain access to a wide range of
                                educational resources, including interactive
                                lessons, quizzes, and progress tracking
                                features.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="benefit-of-collaboration-body-content-box">
                          <div className="row">
                            <div className="col-lg-5">
                              <h5 className="col-lg-10">Customization</h5>
                            </div>
                            <div className="col-lg-7">
                              <p>
                                We understand that different educational
                                institutions and organizations have unique
                                requirements. Our collaborative partners can
                                enjoy the flexibility of customizing the app to
                                suit their specific needs.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="benefit-of-collaboration-body-content-box"
                          style={{ borderBottom: "none" }}
                        >
                          <div className="row">
                            <div className="col-lg-5">
                              <h5 className="col-lg-10">Technical Support</h5>
                            </div>
                            <div className="col-lg-7">
                              <p>
                                We provide dedicated technical support to our
                                collaborators, ensuring a seamless experience
                                for both educators and learners.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <section className="container py-5 partnership-page-contact-section">
          <div
            className="text-center partnership-page-contact-section-header pb-3"
            extratext="Contact us"
          >
            <h3>Send a message</h3>
          </div>
          <div className="row gap-5 gap-lg-0">
            <div className="col-lg-8 mx-auto partnership-page-contact-section-form order-0 order-lg-1">
              <form>
                <div className="mb-4">
                  <input type="text" name="" placeholder="Full name" id="" />
                </div>

                <div className="mb-4">
                  <input
                    type="email"
                    name=""
                    id=""
                    placeholder="Email address"
                  />
                </div>

                <div className="mb-4">
                  <input type="tel" name="" id="" placeholder="Phone Number" />
                </div>

                <div className="mb-4">
                  <textarea
                    name=""
                    placeholder="Message"
                    id=""
                    cols="30"
                    rows="4"
                  ></textarea>
                </div>

                <div>
                  <button
                    disabled
                    className="purple-btn justify-content-center d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span>Send Message</span>
                    <span>
                      <img src={WhiteArrow} alt="" style={{ width: "14px" }} />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/* <Contact /> */}
        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default Partnership;
