import { createContext, useEffect, useState } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    return JSON.parse(localStorage.getItem("auth")) || {};
  });
  const [userData, setUserData] = useState(() => {
    return JSON.parse(localStorage.getItem("userData")) || {};
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  return (
    <DataContext.Provider value={{ auth, setAuth, userData, setUserData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
