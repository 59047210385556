import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

// images
import GooglePlayBtn from "../assets/images/google-playstore.png";
import AppStoreBtn from "../assets/images/app-store.png";
import BlackArrow from "../assets/images/black-arrow.png";

// texts
import Section1 from "./PinnedSections/ExamPass/Section1";
import Section2 from "./PinnedSections/ExamPass/Section2";
import Section3 from "./PinnedSections/ExamPass/Section3";
import Section4 from "./PinnedSections/ExamPass/Section4";
import Section5 from "./PinnedSections/ExamPass/Section5";
import Section6 from "./PinnedSections/ExamPass/Section6";

const ExamPassData = [
  {
    title: "Concise Lesson Notes",
    details:
      "comprehensive, detailed, up-to-date & self-explanatory notes spanning all topics, concepts, theories, laws, equations, formulas, subjects and levels of education.",
  },
  {
    title: "Interactive Instructional Videos",
    details:
      "Interactive Instructional Videos: Enjoy the best of blended learning with our high-quality, interactive videos. Pause & play as you want and learn at your own pace. Learning can be fun too!",
  },
  {
    title: "Experienced Subject Teachers",
    details:
      "Our tutors are subject-matter experts with decades of experience in their niche.",
  },
  {
    title: "Online/Offline Mock Test (CBT)",
    details:
      "Get in top shape for examinations with access to over 40,000+ IGSCE, A-LEVELS, UTME, WAEC, BECE and Common Entrance Past questions and ready-made solutions.",
  },
  {
    title: "Quiz & Performance Analysis",
    details:
      "Assess your knowledge with our topic-related quizzes at the end of each lesson. Consolidate your strengths and get tips for necessary improvement.",
  },
  {
    title: "Tutor Live Chat",
    details:
      "Get help within seconds from expert tutors through our 24/7 Tutor Live Chat.",
  },
];

const imageVariant = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.6, type: "spring", bounce: 0.4 },
  },
  leave: {
    x: 100,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
      bounce: 0.2,
      // delay: 0.5,
    },
  },
};

const SECTIONS = {
  1: <Section1 />,
  2: <Section2 />,
  3: <Section3 />,
  4: <Section4 />,
  5: <Section5 />,
  6: <Section6 />,
};

const SECTIONSIMAGES = {
  1: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1681187989/A1tutor%20website/revert5_dvtq6y.png",
  2: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672097602/A1tutor%20website/phone2_mpsgpr.png",
  3: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1681188163/A1tutor%20website/revert1_uq0pmj.png",
  4: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672097602/A1tutor%20website/phone4_iyeoeb.png",
  5: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672099180/A1tutor%20website/class-attendance_pynwdy.png",
  6: "https://res.cloudinary.com/aonetutor-ltd/image/upload/v1681188305/A1tutor%20website/revert4_qv2kn3.png",
};

const ExamPass = () => {
  const [activeSection, setActiveSection] = useState(1);
  const [downloadLink, setDownloadLink] = useState("");
  useEffect(() => {
    if (window.navigator.userAgent.match(/Android/i)) {
      // Device is an Android
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // Device is an iPhone, iPad, or iPod
      setDownloadLink("https://apps.apple.com/ng/app/a1tutor/id1595970443");
    } else if (window.navigator.userAgent.match(/IEMobile/i)) {
      // Device is a Windows Phone
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else {
      // Device is a desktop or laptop
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    }
  }, []);

  const mainTargetRef = useRef(null);
  const firstTargetRef = useRef(null);
  const secondTargetRef = useRef(null);
  const thirdTargetRef = useRef(null);
  const fourthTargetRef = useRef(null);
  const fifthTargetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(mainTargetRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(2);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(firstTargetRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(3);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(secondTargetRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(4);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(thirdTargetRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(5);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(fourthTargetRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveSection(6);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(fifthTargetRef.current);
  }, []);

  return (
    <>
      <div className="d-none d-md-block" style={{ height: "850vh" }}>
        <section
          id="exam-pass"
          className="container-fluid d-none d-md-flex exam-pass py-5 "
          style={{ height: "100vh", position: "sticky", top: 0 }}
        >
          <div
            className="container d-grid place-content-center"
            style={{ minHeight: "80vh" }}
          >
            <div className="row g-5">
              <div className="col-lg-7 col-md-6  my-xxl-auto">
                <div className="exam-pass-content">
                  <div className="my-5">
                    <h3 id="eve">
                      <span className="tx-blue">Everything</span> you need{" "}
                      <br className="d-none d-lg-block" /> to pass your exam
                    </h3>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <div
                      className="dynamic-text"
                      style={{ minHeight: "250px" }}
                    >
                      <AnimatePresence exitBeforeEnter>
                        <div key={activeSection}>{SECTIONS[activeSection]}</div>
                      </AnimatePresence>
                    </div>

                    <div className="mb-3">
                      <a href={downloadLink} style={{ textDecoration: "none" }}>
                        <button
                          className="trans-btn mb-3 justify-content-center d-flex align-items-center"
                          style={{ gap: "5px" }}
                        >
                          <span>Download App</span>
                          <span>
                            <img
                              src={BlackArrow}
                              alt=""
                              style={{ width: "14px" }}
                            />
                          </span>
                        </button>
                      </a>
                      <div className="d-flex gap-1">
                        <a href="https://apps.apple.com/ng/app/a1tutor/id1595970443">
                          <img src={AppStoreBtn} alt="app store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app">
                          <img src={GooglePlayBtn} alt="google play" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6 exam-pass-phone pt-5 d-flex align-items-center justify-content-md-center justify-content-start">
                <AnimatePresence exitBeforeEnter>
                  <div
                    key={activeSection}
                    className="my-auto text-center exam-pass-phone-img-wrapper "
                  >
                    <motion.img
                      variants={imageVariant}
                      exit="leave"
                      initial="hidden"
                      animate="visible"
                      transition={{ ease: "easeOut", duration: 0.5 }}
                      src={SECTIONSIMAGES[activeSection]}
                      alt="app phone"
                      style={{ width: "100%" }}
                    />
                  </div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </section>
        <div ref={mainTargetRef} style={{ height: "100vh" }}></div>
        <div ref={firstTargetRef} style={{ height: "100vh" }}></div>
        <div ref={secondTargetRef} style={{ height: "100vh" }}></div>
        <div ref={thirdTargetRef} style={{ height: "100vh" }}></div>
        <div ref={fourthTargetRef} style={{ height: "100vh" }}></div>
        <div ref={fifthTargetRef} style={{ height: "100vh" }}></div>
      </div>

      {/* small tablets */}
      <section className="container-fluid exam-pass py-5 d-md-none">
        <div className="container">
          <div className="row g-5">
            <div className="mx-auto">
              <div className="exam-pass-content ">
                <div className="mb-5">
                  <h3 id="eve" data-aos="fade-up">
                    <span className="tx-blue">Everything</span> you need{" "}
                    <br className="d-none d-md-block" /> to pass your exam
                  </h3>
                </div>

                <div className="row g-3">
                  {ExamPassData.map((examData, i) => (
                    <div className="col-12" key={i}>
                      <div className="" data-aos="fade-right">
                        <h5 className="mb-3 tx-blue">{examData.title}</h5>
                        <p className="col-lg-9">{examData.details}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="my-5" data-aos="fade-up">
                  <a href={downloadLink} style={{ textDecoration: "none" }}>
                    <button
                      className="trans-btn justify-content-center d-flex align-items-center mb-3"
                      style={{ gap: "5px" }}
                    >
                      <span>Download App</span>
                      <span>
                        <img
                          src={BlackArrow}
                          alt=""
                          style={{ width: "15px" }}
                        />
                      </span>
                    </button>
                  </a>
                  <div className="d-flex gap-1">
                    <a href="https://apps.apple.com/ng/app/a1tutor/id1595970443">
                      <img
                        src={AppStoreBtn}
                        alt="app store"
                        className="app-btn"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app">
                      <img
                        src={GooglePlayBtn}
                        alt="google play"
                        className="app-btn"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamPass;
