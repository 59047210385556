import React from 'react' 
// import TestimonialPerson from '../assets/images/testimonial-person.png'

const TestimonialCard = ({testimonial}) => {
  return (
    <div className="testimonials-card">
        <div className="testimonials-card-header">
            <img src={testimonial.image} alt="Person" />
            <div>
                <h6>{testimonial.name}</h6>
                {/* <p>Lekki</p> */}
            </div>
        </div>

        <div className="testimonials-card-body">
            <p>{testimonial.review}</p>
        </div>
    </div>
  )
}

export default TestimonialCard