import React from 'react'

const BubbleSection = () => {
  return (
    <div className='bubble-section'>
        <div className="x">
            <div className="bubble y purple-bubble">s</div>
        </div>  

        <div className="a">
            <div className="bubble b lemon-bubble">s</div>
        </div> 

        <div className="c">
            <div className="bubble d yellow-bubble">s</div>
        </div> 

        <div className="e">
            <div className="bubble f blue-bubble">s</div>
        </div>   
    </div>
  )
}

export default BubbleSection