import React from "react";
import ComingSoon from "../../assets/images/coming.gif";

const DownloadModal = ({ setOpenDownloadModal }) => {
  return (
    <div className="download-modal">
      <div className="download-modal-box col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-sm-7 col-10 px-3 py-5">
        <button className="close" onClick={() => setOpenDownloadModal(false)}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                d="M1.88402 0.456548C1.75985 0.336616 1.59355 0.270253 1.42094 0.271753C1.24832 0.273254 1.08319 0.342497 0.96113 0.464569C0.839066 0.586641 0.769828 0.751775 0.768328 0.924404C0.766828 1.09703 0.833186 1.26335 0.95311 1.38752L4.7551 5.18976L0.952452 8.992C0.889573 9.05274 0.839418 9.12539 0.804914 9.20572C0.770411 9.28604 0.752249 9.37244 0.75149 9.45986C0.75073 9.54728 0.767387 9.63398 0.80049 9.71489C0.833592 9.7958 0.882477 9.86932 0.944292 9.93114C1.00611 9.99295 1.07961 10.0418 1.16052 10.0749C1.24143 10.1081 1.32812 10.1247 1.41554 10.124C1.50295 10.1232 1.58934 10.105 1.66966 10.0705C1.74999 10.036 1.82263 9.98586 1.88336 9.92297L5.68601 6.12073L9.48799 9.92297C9.61216 10.0429 9.77846 10.1093 9.95108 10.1078C10.1237 10.1063 10.2888 10.037 10.4109 9.91496C10.5329 9.79288 10.6022 9.62775 10.6037 9.45512C10.6052 9.28249 10.5388 9.11618 10.4189 8.992L6.61692 5.18976L10.4189 1.38752C10.5388 1.26335 10.6052 1.09703 10.6037 0.924404C10.6022 0.751775 10.5329 0.586641 10.4109 0.464569C10.2888 0.342497 10.1237 0.273254 9.95108 0.271753C9.77846 0.270253 9.61216 0.336616 9.48799 0.456548L5.68601 4.25879L1.88402 0.45589V0.456548Z"
                fill="#E90000"
              />
            </svg>
          </span>
        </button>
        <div className="download-modal-box-header">
          <h3>Download Now</h3>
        </div>

        <div className="download-modal-box-body">
          <a
            className="text-decoration-none"
            href="https://myadmissionlink.nyc3.cdn.digitaloceanspaces.com/a1tutor-desktop/v1/a1tutor-1.0.0-setup.exe"
            download
          >
            <button className=" mx-auto">
              <span className="d-flex align-items-center gap-1">
                <span className="text"> Download for Windows</span>
                <span className="icon">
                  <svg
                    class="download-logo"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    focusable="false"
                  >
                    <path d="M1.589 23.55L1.572 8.24l18.839-2.558V23.55zM23.55 5.225l25.112-3.654V23.55H23.55zM48.669 26.69l-.006 21.979-25.112-3.533V26.69zM20.41 44.736l-18.824-2.58-.001-15.466H20.41z"></path>
                  </svg>
                </span>
              </span>
            </button>
          </a>
          <button className=" mx-auto">
            <span className="d-flex align-items-center gap-1">
              <span className="icon">
                {" "}
                <svg
                  class="download-logo"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  focusable="false"
                >
                  <path d="M39.054 34.065q-1.093 3.504-3.448 7.009-3.617 5.495-7.205 5.495-1.374 0-3.925-.897-2.411-.897-4.233-.897-1.71 0-3.981.925-2.271.953-3.701.953-4.261 0-8.439-7.261Q.001 32.075.001 25.29q0-6.392 3.168-10.485 3.14-4.037 7.962-4.037 2.019 0 4.962.841 2.916.841 3.869.841 1.262 0 4.009-.953 2.86-.953 4.85-.953 3.336 0 5.972 1.822 1.458 1.009 2.916 2.804-2.215 1.878-3.196 3.308-1.822 2.635-1.822 5.803 0 3.476 1.934 6.252t4.43 3.533zM28.512 1.179q0 1.71-.813 3.813-.841 2.103-2.607 3.869-1.514 1.514-3.028 2.019-1.037.308-2.916.477.084-4.177 2.187-7.205 2.075-3 7.009-4.149.028.084.07.308t.07.308q0 .112.014.28t.014.28z"></path>
                </svg>
              </span>
              <span className="text"> Download for MacOs</span>
            </span>
            <span className="coming-soon">
              <img src={ComingSoon} alt="" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
