import React from "react";
import Book from "../assets/images/book.png";
import Phone from "../assets/images/info-phone.png";

const InfoBanner = () => {
  return (
    <div className="container-fluid info_banner">
      <div className="container p-2 d-flex flex-lg-row flex-column gap-2 justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-1 gap-md-3">
          <img src={Book} alt="Book" />
          <p className="mb-0">
            Get the best online courses to pass that exam and get the grade you
            want
          </p>
        </div>

        <div className="d-flex gap-2 gap-md-3 align-items-center">
          <div className="">
            <img src={Phone} alt="phone" style={{ width: "13px" }} />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <span>0912 770 8307</span>
            <span>0912 612 5331</span>
            {/* <span>09127708307</span> */}
            {/* <span>07771030909</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBanner;
