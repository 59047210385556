import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

const MobileNav = ({ closeNav, navActive }) => {
  let activeStyle = {
    fontWeight: "900",
    color: "#4b9dd8",
  };

  return (
    <div className={`mobile-nav ${navActive ? "active" : ""}`}>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <img src={Logo} alt="" width="30" className="inline-flex logo" />
        <div
          className="close-hamburg d-flex d-lg-none justify-content-end align-items-center gap-1"
          onClick={closeNav}
        >
          <p className="mb-0 fw-bold">CLOSE</p>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div className="mobile-nav-content d-flex justify-content-between flex-column">
        <div style={{ minHeight: "55vh" }} className="mobile-nav-content-links">
          <div className="">
            {/* <h6>LINKS</h6> */}

            <div>
              <ul className="mb-0 ms-0">
                <li>
                  <NavLink
                    to="/"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/blog"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/pricing"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    FAQS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/partnership"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Partnership
                  </NavLink>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app">
                    DOWNLOAD
                  </a>
                </li>
                {/* <li>
                  <NavLink
                    to="/login"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    GET STARTED
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="mobile-nav-content-contact">
          {/* <h6>CONTACT US</h6> */}
          <div className="mb-2">
            <a href="mailto:contact@a1tutor.org">contact@a1tutor.org</a>
            {/* <a href="tel:09126125331">+234 912 612 5331, +234 912 770 8307</a> */}
            <a href="tel:09127708307">0912 770 8307</a>
          </div>
          <div>
            <p>8 Abdulrasaq Street, GRA, Ilorin, Nigeria. </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
