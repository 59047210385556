import React from "react";

const BlogLoader = ({ height, marginTop }) => {
  return (
    <div
      className="blog-loader"
      style={{ height, marginTop: marginTop || "-20vh" }}
    >
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default BlogLoader;
