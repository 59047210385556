import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";
import MobileNav from "./MobileNav";

const NavBar = ({ setOpenDownloadModal }) => {
  const [navActive, setNavActive] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    if (window.navigator.userAgent.match(/Android/i)) {
      // Device is an Android
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // Device is an iPhone, iPad, or iPod
      setDownloadLink("https://apps.apple.com/ng/app/a1tutor/id1595970443");
    } else if (window.navigator.userAgent.match(/IEMobile/i)) {
      // Device is a Windows Phone
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else {
      // Device is a desktop or laptop
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    }
  }, []);

  // let activeStyle = {
  //   fontWeight: "900",
  //   textDecoration: "underline",
  // };

  const closeNav = () => {
    setNavActive(false);
  };

  return (
    <>
      <nav className="container-fluid py-3 py-sm-3 py-xxl-4">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between justify-content-lg-start gap-3 nav">
            <Link to="/" className="logo-box">
              <img src={Logo} alt="" width="30" className="inline-flex logo" />
            </Link>

            {/* <div className="nav-box"> */}
            <ul className="d-none d-lg-flex nav-box-list mb-0 ms-0">
              <li>
                <NavLink
                  to="/"
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Home
                  <svg
                    fill="#000"
                    viewBox="-5 -5 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  About Us
                  <svg
                    fill="#000"
                    viewBox="-10 -5 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog"
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <span>Blog</span>
                  <svg
                    fill="#000"
                    viewBox="-5 -5 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/pricing"
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Pricing
                  <svg
                    fill="#000"
                    viewBox="-5 -5 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  FAQS
                  <svg
                    fill="#000"
                    viewBox="-5 -5 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/partnership" 
                >
                  Partnership
                  <svg
                    fill="#000"
                    viewBox="-20 -3 190.24 80.42"
                    className="menu-item-circle nav-svg"
                  >
                    <path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path>
                  </svg>
                </NavLink>
              </li> */}
            </ul>

            <ul className="nav-buttons mb-0 ms-0 d-none d-lg-block">
              <a href={downloadLink}>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="login-btn"
                >
                  Download
                </motion.button>
              </a>

              <button
                className="get-started-btn d-none d-lg-inline-block"
                onClick={() => setOpenDownloadModal(true)}
              >
                Get Desktop App
              </button>
            </ul>
            {/* </div> */}

            <div
              className="d-lg-none hamburg"
              onClick={() => setNavActive(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>

      <MobileNav closeNav={closeNav} navActive={navActive} />
    </>
  );
};

export default NavBar;
