import React from 'react'
import BusinessDay from '../assets/images/business-day.png'
import Vanguard from '../assets/images/vanguard.png'
import Technext from '../assets/images/technext.png'

const News = () => {
  return (
    <section className="container-fluid news py-5">
        <div className="container">
            <div className="row">
                <div className="col-md-6 text-center mx-auto news-header">
                    <h3 data-aos="fade-up">As featured on</h3>
                </div>
                <div className="col-md-10 mx-auto text-center py-3">
                    <div className='d-flex flex-md-row flex-column justify-content-center align-items-center gap-5 gap-md-4'>
                        <img src={BusinessDay} alt="" />
                        <img src={Vanguard} alt="" />
                        <img src={Technext} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default News