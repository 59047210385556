import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import SearchInput from "../../../Components/shared/dashboard/SearchInput";
import useData from "../../../hooks/useData";

const Transaction = () => {
  const { auth } = useData();
  const [transactionData, setTransactionData] = useState({
    data: [],
    isFetching: true,
  });
  const { data: transaction_data, isFetching } = transactionData;

  useEffect(() => {
    setTransactionData({ ...transactionData, isFetching: true });
    async function getTransactions() {
      try {
        const response = await axios.get(`get/user/subscription/payments`, {
          headers: {
            Authorization: `Bearer ${auth.access_token}`,
            Accept: "*",
          },
        });

        setTransactionData({
          ...transactionData,
          isFetching: false,
          data: response.data.data,
        });
      } catch (err) {
        console.log(err);
        setTransactionData({ ...transactionData, isFetching: false, data: [] });
      }
    }

    getTransactions();
  }, []);

  return (
    <>
      <div className="transaction">
        <div className="d-flex gap-2 align-items-center top-title-bar">
          <h3 className="mb-0">Transactions</h3>
        </div>

        <div className="row mb-5">
          <div className="col-xxl-5 col-xl-6 col-lg-7">
            <SearchInput />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-9 col-xl-7 col-xxl-5">
            <div className="row g-3">
              <div className="col-md-4 col-lg-6 col-sm-6">
                <div className="transaction-box one">
                  <h5>Total Transactions</h5>
                  <h2>{transaction_data.length || "??"}</h2>
                </div>
              </div>

              <div className="col-md-4 col-lg-6 col-sm-6">
                <div className="transaction-box two">
                  <h5>Pending Transactions</h5>
                  <h2>0</h2>
                </div>
              </div>

              <div className="col-md-4 col-lg-6 col-sm-6">
                <div className="transaction-box three">
                  <h5>Failed Transactions</h5>
                  <h2>0</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="transaction-table">
            <h4 className="transaction-table-title">Transaction History</h4>
            <div className="transaction-table-box">
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Reference ID</th>
                    <th>Description</th>
                    <th>Amount Paid</th>
                    <th>Payment Method</th>
                    <th>Subscription Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching && "Loading..."}
                  {!isFetching &&
                    transaction_data.length === 0 &&
                    "No result found"}
                  {!isFetching &&
                    transaction_data.length !== 0 &&
                    transaction_data.map((_, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{_?.reference}</td>
                        <td>{_?.payment_description}</td>
                        <td>&#8358; {_?.amount}</td>
                        <td>{_?.payment_method}</td>
                        <td>
                          {_?.expired === 1 ? (
                            <span className="bg-red badge">Expired</span>
                          ) : (
                            <span className="badge bg-green badge-md">
                              Not Expired
                            </span>
                          )}
                        </td>
                        <td>
                          {new Date(_?.date).toLocaleDateString("en-Gb", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
