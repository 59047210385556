import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import BlogLoader from "../Components/BlogLoader";
import useData from "../hooks/useData";

export const MobileLoginReroutePage = () => {
  const location = useLocation();
  const { setUserData, setAuth } = useData();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [navigate, setNavigate] = useState(false);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const response = await axios.get(`user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
 
        const { data } = response.data;
        const { email, first_name, last_name, phone } = data;
        setAuth({ email, first_name, last_name, phone, access_token: token });
        setUserData(data);

        if (response.status === 200) {
          toast.success("Authentication Successful", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        setTimeout(() => {
          toast.info("Redirecting to your dashboard", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }, 1700);

        setTimeout(() => {
          setNavigate(true);
        }, 3300);
      } catch (err) {
        console.log(err);
      }
    }

    getUserDetails();
  }, []);

  if (navigate) {
    return <Navigate to="/dashboard/payments" replace={true} />;
  }
  return (
    <div>
      <BlogLoader height="100vh" marginTop="0" />
    </div>
  );
};
