import React  from 'react'
import { motion } from 'framer-motion'

const Stats = () => {

    // const [lessons, setLessons] = useState(0)
    // const [tutors, setTutors] = useState(0)
    // const [pastQuestions, setPastQuestions] = useState(0)

    // useEffect(() => {
    //   setLessons(prevState => prevState + 1)

    //   if(lessons === '2000') {
    //     return false
    //   }
    // }, [lessons])
    // useEffect(() => {
    //     if (lessons < 3000) {
    //       setTimeout(() => {
    //         setLessons((lessons) => lessons + 30);
    //       }, 50);
    //     }
    //   }, [lessons]);

      // useEffect(() => {
      //   if ( tutors < 30) {
      //     setTimeout(() => {
      //       setTutors((tutors) => tutors + 1);
      //     }, 100);
      //   }
      // }, [tutors]);

      // useEffect(() => {
      //   if ( pastQuestions < 40000) {
      //     setTimeout(() => {
      //       setPastQuestions((pastQuestions) => pastQuestions + 400);
      //     }, 50);
      //   }
      // }, [pastQuestions]);
    
    
  return (
    <section className="container-fluid stat-section py-4">
        <div className="container stat-section-content">
            <div className="row">
                <div className="col-lg-7 text-center mx-auto">
                    <div className="row g-5">
                    <div className="col-md-6">
                        <div className='stat'> 
                            <motion.h3>+3,000</motion.h3>
                            <h6 className="">Lessons</h6>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                        <div className='stat'>
                            <h3 className="">+{tutors}</h3>
                            <h6 className="">Tutors</h6>
                        </div>
                    </div> */}
                    <div className="col-md-6 ">
                        <div className='stat'>
                            <h3 className="">+40,000</h3>
                            <h6 className="">Past Questions</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Stats