import React from "react";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="register-form-card p-2 p-md-4 p-xxl-5 col-md-10 col-lg-10 col-xxl-8 mx-auto">
      <div className="register-form-card-header mb-3 mb-xxl-5">
        <h3 className="tx-blue">Register Now</h3>
        <h6>
          Get 7 Days{" "}
          <span className="fw-bold">
            <i>Free Trial</i>
          </span>{" "}
          to Start learning with Fun
        </h6>
      </div>

      <form>
        <div className="mb-5">
          <div className="mb-4">
            <input type="text" name="" placeholder="Full name" id="" />
          </div>

          <div className="mb-4">
            <input type="email" name="" id="" placeholder="Email address" />
          </div>

          <div className="mb-4">
            <input type="text" name="" placeholder="Phone number" id="" />
          </div>
        </div>

        <div>
          <button className="">Register</button>
        </div>
      </form>

      <div className="mt-3 text-center">
        <p style={{ letterSpacing: "-1px", color: "#aaa" }}>
          Already have an account?{" "}
          <Link to="/login" className="tx-blue">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
