import React from 'react'
import { motion } from 'framer-motion' 


const sectionVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      transition: { ease: "easeOut", duration: 0.5 },
    },
};

const titleVariant = {
    hidden: {
      y: 50,
    },
    visible: {
      y: 0,
    },
    leave: {
      y: 50,
      transition: { ease: "easeOut", duration: 0.5 },
    },
};



const Section4 = () => {
  return (   
        <motion.div variants={sectionVariant}
            key="one"
            exit="leave"
            initial="hidden"
            animate="visible" 
            transition={{ ease: "easeOut", duration: 0.5 }} className="mb-5 pe-lg-5 text1  py-lg-2 py-xxl-5">
            <motion.h5 
                variants={titleVariant}
                transition={{ ease: "easeOut", duration: 0.5 }} className='mb-3'>
                    Online/Offline Mock Test (CBT)
            </motion.h5>
            <motion.p   
                variants={titleVariant}
                transition={{ ease: "easeOut", duration: 0.5 }} className='col-lg-9'>
                    Get in top shape for examinations with access to over 40,000+ IGSCE, A-LEVELS, UTME, WAEC, BECE and Common Entrance Past questions and ready-made solutions.
            </motion.p>
        </motion.div>
  )
}

export default Section4