import React, { useState } from "react";
// import SearchIcon from '../assets/icons/Search.png'
import Footer from "../Components/Footer";
import InfoBanner from "../Components/InfoBanner";
import DownloadModal from "../Components/modal/DownloadModal";
import NavBar from "../Components/NavBar";
import IntroTitle from "../Components/shared/IntroTitle";

const FaqData = [
  {
    question: "What is Unique about the A1Tutor App?",
    answer:
      "Our educational content is well-researched, carefully curated and expertly delivered by seasoned tutors across several specialisations. Our content is prepared in line with the approved educational curriculum for learners by examination bodies such as NCEE (Common Entrance), BECE, IGSCE, WAEC, JAMB, IJMB & JUPEB.",
  },
  {
    question: "How do I get the A1Tutor app?",
    answer:
      "The A1tutor app is available on both Google Play here and App Store. Our content can also be accessed via a web browser.",
  },
  // {
  //   question:
  //     "How do I confirm that my online payment was received and processed?",
  //   answer:
  //     "You will be notified duly and the plan activated. Kindly proceed to enjoy our amazing resources materials.",
  // },
  {
    question: "Will the App consume my data when not in use?",
    answer:
      "No, It Won’t. However, ensure you restrict background applications from using your data.",
  },
  {
    question: "What do I need to signup?",
    answer: "All you need is your email address and phone number.",
  },
  {
    question:
      "Are the lessons structured according to the curriculum/syllabus?",
    answer:
      "Yes, the lessons are structured in line with the NERDC Standard Curriculum",
  },
  {
    question: "Do I need data to access the A1Tutor App?",
    answer:
      "To access it for the first time, yes. However, once video downloads have been made, you can access them offline. I.e. without data.",
  },
  {
    question: "How can I subscribe after getting the app?",
    // answer:
    //   "To Subscribe, once signed in, kindly click launch the A1tutor App, select profile, scroll to subscription package, select more info, click on the periodic plan of your choice, select ‘I want this plan’ insert Card details then complete payment.",
    answer: "The A1Tutor app is free to use for all learners."
  },
  // {
  //   question: "What subscription packages are available?",
  //   answer:
  //     "The standard plan, Premium plan and Exam preparation (CBT) Plan. Which can be monthly, quarterly or yearly.",
  // },
  {
    question: "What type of devices is the app compatible with?",
    answer:
      "A1tutor App is compatible with Android and Apple devices. You can also access it via your web browser.",
  },
  {
    question: "Are the trial lessons free?",
    answer: "Yes, the trial lessons are free.",
  },
  {
    question: "What range of classes does A1Tutor cater for?",
    answer:
      "Learners in Primary School (Class 1-6), Junior Secondary School (JSS 1-3), Senior Secondary School (SSS 1-3) and A-levels (IJMB & JUPEB) are well catered for.",
  },
  {
    question: "How do I track and monitor my child's progress",
    answer:
      "Simply log in to the parent portal customized specially for you. Through this, you would have access to their test results and see what they have been learning.",
  },
  {
    question: "Will the students be able to ask questions?",
    answer: "Yes, we have a proprietary live chat feature for all learners.",
  },
];

const Faq = () => {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  return (
    <>
    <main className="container-fluid px-0">
      <InfoBanner />

      <NavBar setOpenDownloadModal={setOpenDownloadModal} />
      <header className="container-fluid faq-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-11 mx-auto faq-header-content">
              <div className="col-lg-12 mx-auto">
                <IntroTitle>Frequently Asked Questions</IntroTitle>
              </div>

              {/* <div className='search-section'>
                  <p className='mb-2 mb-md-2'>Search question here</p>
                  <div className='box'>
                    <img src={SearchIcon} style={{width: "25px"}} alt="" />
                    <input type="search" name="" id="" />
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </header>
      <section className="container-fluid faq py-5">
        <div className="container">
          <div className="row py-5">
            <div className="col-md-9 col-md-8 mx-auto col-12">
              <div className="accordion accordion-flush" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button py-3 py-md-4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is A1Tutor
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mb-0 tx-blue tx-16">
                        A1Tutor Ltd. is an Edtech company that offers
                        personalized learning content to learners in Africa. We
                        make use of instructional videos, topic-wise lesson
                        notes, quizzes, and CBT to provide asynchronous learning
                        instructions to learners in the Primary School (Class
                        1-6), Junior Secondary School(JSS1-3), Senior Secondary
                        School(SS1-3), A'Levels (IJMB Cambridge & JUPEB) and
                        learners preparing for different categories of
                        examinations: JSCE, WAEC, UTME, JUPEB and more.
                      </p>
                    </div>
                  </div>
                </div>

                {FaqData.map((Faq, i) => (
                  <div className="accordion-item mt-3" key={i}>
                    <h2 className="accordion-header" id={`heading${i + 1}`}>
                      <button
                        className="accordion-button collapsed  py-3 py-md-4"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${i + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse${i + 1}`}
                      >
                        {Faq.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${i + 1}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${i + 1}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="mb-0 tx-blue tx-16">{Faq.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
    {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default Faq;
