import React from "react";
import { motion } from "framer-motion";

const IntroTitle = ({ children }) => {
  return (
    <motion.h1
      initial={{ scale: 1.3, y: -50, opacity: 0.1 }}
      whileInView={{
        y: 0,
        scale: 1,
        opacity: 1,
        transition: { duration: 0.3 },
      }}
      exit={{ scale: 1.3, y: -50, opacity: 0.1 }}
    >
      {" "}
      {children}
    </motion.h1>
  );
};

export default IntroTitle;
