import React from "react";
import { Link } from "react-router-dom";
import SuccessGif from "../assets/images/successfully-done.gif";

const SuccessPage = () => {
  return (
    <div>
      <div
        className="container d-flex justify-content-center align-items-center success-box"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="col-xl-5 col-lg-6 mx-auto col-sm-10 col-md-8 col-12 p-5"
          style={{ minHeight: "400px", border: "1px solid #bbb" }}
        >
          <div className="text-center">
            <div style={{ minHeight: "50px" }}>
              <img src={SuccessGif} alt="" width="200" />
            </div>
            <h1>Payment Successful</h1>
          </div>

          <div className="text-center mt-5">
            <Link to="/dashboard/transactions">
              <button className="get-started-btn">View Transactions</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
