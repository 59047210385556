import React, { useEffect, useState } from "react";

import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import WhiteArrow from "../../../assets/images/button-arrow.png";
import LeftArrow from "../../../assets/images/left-circle-arrow.svg";
import Tick from "../../../assets/images/price-tick.png";
import useData from "../../../hooks/useData";
// import LeftArrow from "../../../assets/images/left-circle-arrow.svg";

const AllPricingData = [
  {
    feature: "Unlimited Access to Lesson Video",
  },
  {
    feature: "Unlimited Access to Lesson Notes",
  },
  {
    feature: "Access to Live Classes",
  },
  {
    feature:
      " Over 40k Examination Preparation Questions and Solutions: WAEC, JAMB, BECE, IGSCE, JUPEB & POST UTME",
  },
  {
    feature: "Offline learning",
  },
  {
    feature: "Ask a Tutor",
  },
];

const PaymentDetails = () => {
  const { auth } = useData();
  const { type } = useParams();
  const { state } = useLocation();
  const data = state.data;

  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    if (state === null) {
      return <Navigate to="/dashboard/payments" replace={true} />;
    }
  }, [state]);

  async function getStarted() {
    const id = toast.loading("Initializing payment, please wait...");
    try {
      const res = await axios.post(
        `subscription/initialize`,
        {
          uuid: data.uuid,
          ...(coupon ? { coupon_code: coupon } : {}),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token}`,
            Accept: "*",
          },
        }
      );

      if (!res.data.status) {
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }

      toast.update(id, {
        render: res.data.data.message || "",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setTimeout(() => {
        toast.update(id, {
          render: "Redirecting to payment gateway",
          type: "info",
          isLoading: false,
          autoClose: 1000,
        });
      }, 1100);

      setTimeout(() => {
        window.location.assign(res.data.data.url);
      }, 2300);
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }
  return (
    <>
      <div className="d-flex gap-2 align-items-center top-title-bar">
        <Link
          to="../payments"
          className="d-flex gap-2 align-items-center top-section-info"
        >
          <img src={LeftArrow} alt="" />
          <p className="mb-0">Back</p>
        </Link>
      </div>

      <div className="d-flex gap-2 align-items-center top-title-bar">
        <h3 className="mb-0">
          {type.charAt(0).toUpperCase() + type.slice(1)} Subscription Features
        </h3>
      </div>

      <div className="subcription-cards row mx-auto px-0">
        <div className="col-lg-12 col-xl-12 col-md-12 px-0" data-aos="zoom-in">
          <div
            className={`box ${
              type === "monthly" ? "one" : type === "termly" ? "two" : "three"
            } mb-4 px-3 py-4 p-sm-5 px-lg-3 py-lg-4 p-xl-4 p-xxl-5`}
          >
            <div className="price-header">
              <h5>{data.subscription_name}</h5>
              {data.subscription_name.toLowerCase() !== "monthly" && (
                <h6 className="slashed-price">
                  &#8358;{" "}
                  {data.subscription_name.toLowerCase() === "termly"
                    ? "14,999"
                    : data.subscription_name.toLowerCase() === "yearly"
                    ? "59,999"
                    : ""}{" "}
                </h6>
              )}
              <h4>
                &#8358; {data.price_naira}{" "}
                <span className="month">
                  /
                  {data.subscription_name.toLowerCase() === "monthly"
                    ? "month"
                    : data.subscription_name.toLowerCase() === "termly"
                    ? "3 Months"
                    : "12 Months"}
                </span>
              </h4>
            </div>

            <div className="my-4 coupon-box">
              <label htmlFor="">Use Coupon (Optional)</label>
              <input
                type="text"
                name=""
                id=""
                placeholder="Enter Coupon code"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>

            <div className="text-center">
              <button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="white-btn justify-content-center d-flex align-items-center"
                style={{ gap: "5px" }}
                onClick={getStarted}
              >
                <span>Pay Now</span>
                <span>
                  <img src={WhiteArrow} alt="" style={{ width: "14px" }} />
                </span>
              </button>
            </div>
            <div className="price-body mt-5">
              {AllPricingData.map((eachData, i) => (
                <div key={i} className="py-4 price-body-item  d-flex">
                  <div>
                    <img src={Tick} alt="" />
                  </div>
                  <div>
                    <p>{eachData.feature}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
