import React, { useEffect } from "react";
import { motion } from "framer-motion";
import AppStore from "../assets/images/app-store.png";
import GooglePlay from "../assets/images/google-playstore.png";

import Arrow from "../assets/images/button-arrow.png";
import BlackArrow from "../assets/images/black-arrow.png";
import InfoBanner from "../Components/InfoBanner";
import NavBar from "../Components/NavBar";
import Stats from "../Components/Stats";
import ExamPass from "../Components/ExamPass";
import Testimonials from "../Components/Testimonials";
import News from "../Components/News";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import HomeHeader from "../Components/HomeHeader";
import { Link } from "react-router-dom";
import { useState } from "react";
import DownloadModal from "../Components/modal/DownloadModal";

const Home = () => {
  const [downloadLink, setDownloadLink] = useState("");
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  useEffect(() => {
    if (window.navigator.userAgent.match(/Android/i)) {
      // Device is an Android
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // Device is an iPhone, iPad, or iPod
      setDownloadLink("https://apps.apple.com/ng/app/a1tutor/id1595970443");
    } else if (window.navigator.userAgent.match(/IEMobile/i)) {
      // Device is a Windows Phone
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else {
      // Device is a desktop or laptop
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    }
  }, []);

  return (
    <>
      <main className="Home container-fluid px-0">
        <InfoBanner />
        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <HomeHeader downloadLink={downloadLink} />
        <Stats />
        <section className="container-fluid data-scroll-section py-5 app-section">
          <div className="container blue-box">
            <div className="row">
              <div className="col-lg-7 py-5">
                <div className="py-md-4">
                  <div className="app-content px-sm-3 px-md-5 px-2 text-center text-lg-start ">
                    <motion.h2
                      data-aos="fade-up"
                      className="mb-3 aos-init aos-animate"
                    >
                      The most simplified learning experience
                    </motion.h2>
                    <p
                      data-aos="fade-up"
                      className="col-md-9 mx-md-auto col-lg-12 mx-lg-0 "
                    >
                      Learn with fun and ace your examinations in flying colors{" "}
                    </p>
                  </div>
                  <div
                    data-aos="fade-up"
                    className="app-buttons-div d-lg-flex align-items-center gap-3 px-sm-5 px-3"
                  >
                    <div className="mb-2 mb-md-4 mb-lg-2 text-center text-lg-start">
                      <a href={downloadLink} style={{ textDecoration: "none" }}>
                        <button
                          className="d-flex align-items-center justify-content-center mx-auto"
                          style={{ gap: "5px" }}
                        >
                          <span>Download App</span>{" "}
                          <span>
                            <img src={Arrow} alt="" width="14" />
                          </span>
                        </button>
                      </a>
                    </div>
                    <div className="d-flex gap-1 gap-md-3 gap-lg-1 justify-content-center justify-lg-start">
                      <a href="https://apps.apple.com/ng/app/a1tutor/id1595970443">
                        <img
                          src={AppStore}
                          alt="app store"
                          className="app-btn"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app">
                        <img
                          src={GooglePlay}
                          alt="google play"
                          className="app-btn"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 d-flex align-items-end px-0">
                <div className="ms-auto">
                  {/* data-aos="fade-up-left"  data-aos-offset='50'  */}
                  <img
                    src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672096671/A1tutor%20website/phone_s5awja.png"
                    alt="phone"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid py-5 my-5 why-section">
          <div className="container">
            <div className="row g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="why-section-content">
                  <div className="mb-md-5 mb-2 col-lg-12">
                    <h3 className="mb-3" data-aos="fade-up">
                      Why learners choose <br className="d-none d-lg-block" />{" "}
                      <span className="tx-blue">A1 Tutor</span>{" "}
                    </h3>
                    <p data-aos="fade-up" className="col-xl-11">
                      Our learning platform offers a comprehensive and flexible
                      learning experience for students of all levels. With live
                      classes, lesson videos, lesson notes, and CBT, you'll have
                      all the tools you need to succeed. Our expert instructors
                      are always available to answer questions and provide
                      support, and our user-friendly platform makes it easy to
                      track your progress and stay motivated. Whether you're
                      preparing for an exam, looking to upskill, or simply
                      interested in learning something new, our platform has
                      something for everyone. Join us and start learning today!
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <Link to="about" style={{ textDecoration: "none" }}>
                      {/* <button className="purple-btn me-md-3 me-1 mb-1">
                      About Us
                    </button> */}
                      <button
                        className="purple-btn  me-md-3 me-3 mb-1 justify-content-center d-flex align-items-center"
                        style={{ gap: "5px" }}
                      >
                        <span>About Us</span>
                        <span>
                          <img src={Arrow} alt="" style={{ width: "14px" }} />
                        </span>
                      </button>
                    </Link>
                    <a href={downloadLink} style={{ textDecoration: "none" }}>
                      <button
                        className="trans-btn justify-content-center d-flex align-items-center"
                        style={{ gap: "5px" }}
                      >
                        <span>Download App</span>
                        <span>
                          <img
                            src={BlackArrow}
                            alt=""
                            style={{ width: "15px" }}
                          />
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6  d-grid place-content-center">
                <div className="text-center my-auto">
                  <iframe
                    className="feature-video"
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    src="https://www.youtube.com/embed/redWrlw_2qk"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ExamPass />
        <Testimonials />
        <News />
        <Contact />
        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default Home;
