import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import WhiteArrow from "../../../assets/images/button-arrow.png";
import useData from "../../../hooks/useData";

const Payments = () => {
  const { auth } = useData();
  const [paymentData, setPaymentData] = useState({
    data: [],
    isFetching: true,
  });

  useEffect(() => {
    async function getPaymentData() {
      setPaymentData({ ...paymentData, isFetching: true });
      try {
        const response = await axios.get(`user/subscription/new`, {
          headers: {
            Authorization: `Bearer ${auth.access_token}`,
          },
        }); 
        setPaymentData({
          ...paymentData,
          data: response.data.data,
          isFetching: false,
        });
      } catch (err) {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    getPaymentData();
  }, []);

  return (
    <>
      <div className="d-flex gap-2 align-items-center top-title-bar">
        <h3 className="mb-0">Payment</h3>
      </div>
      <div className="subcription-cards row mx-auto px-0">
        {paymentData.isFetching && "Loading..."}
        {!paymentData.isFetching &&
          paymentData?.data?.length === 0 &&
          "No data Found"}
        {!paymentData.isFetching &&
          paymentData?.data?.length !== 0 &&
          paymentData?.data.map((_, i) => (
            <div
              key={i}
              className="col-lg-10 col-xl-8 col-xxl-7 col-md-10"
              data-aos="zoom-in"
            >
              <div
                className={`box ${
                  i === 0 ? "one" : i === 1 ? "two" : "three"
                } mb-4 px-3 py-4 p-sm-5 p-5 row`}
              >
                <div className="price-header col-lg-7">
                  <h5>{_.subscription_name}</h5>

                  {_.subscription_name.toLowerCase() !== "monthly" && (
                    <h6 className="slashed-price">
                      &#8358;{" "}
                      {_.subscription_name.toLowerCase() === "termly"
                        ? "14,999"
                        : _.subscription_name.toLowerCase() === "yearly"
                        ? "59,999"
                        : ""}{" "}
                    </h6>
                  )}
                  <h4>
                    &#8358; {_.price_naira}{" "}
                    <span className="month">
                      /
                      {_.subscription_name.toLowerCase() === "monthly"
                        ? "month"
                        : _.subscription_name.toLowerCase() === "termly"
                        ? "3 Months"
                        : "12 Months"}
                    </span>
                  </h4>
                </div>
                <div className="text-center col-lg-5 d-flex align-items-center">
                  <Link
                    to={_.subscription_name.toLowerCase()}
                    state={{ data: _ }}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="white-btn justify-content-center d-flex align-items-center mx-auto"
                      style={{ gap: "5px" }}
                    >
                      <span>View</span>
                      <span>
                        <img
                          src={WhiteArrow}
                          alt=""
                          style={{ width: "14px" }}
                        />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Payments;
