import React from "react";
import { motion } from "framer-motion";

const sectionVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.5 },
  },
};

const titleVariant = {
  hidden: {
    y: 50,
  },
  visible: {
    y: 0,
  },
  leave: {
    y: 50,
    transition: { ease: "easeOut", duration: 0.5 },
  },
};

const Section2 = () => {
  return (
    <motion.div
      variants={sectionVariant}
      key="one"
      exit="leave"
      initial="hidden"
      animate="visible"
      transition={{ ease: "easeInOut", duration: 0.5 }}
      className="mb-5 pe-lg-5 text1  py-lg-2 py-xxl-5"
    >
      <motion.h5
        variants={titleVariant}
        transition={{ ease: "easeOut", duration: 0.5 }}
        className="mb-3"
      >
        Interactive Instructional Videos
      </motion.h5>
      <motion.p
        variants={titleVariant}
        transition={{ ease: "easeOut", duration: 0.5 }}
        className="col-lg-9"
      >
        Interactive Instructional Videos: Enjoy the best of blended learning
        with our high-quality, interactive videos. Pause & play as you want and
        learn at your own pace. Learning can be fun too!
      </motion.p>
    </motion.div>
  );
};

export default Section2;
