import SanityBlockContent from "@sanity/block-content-to-react";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import client from "../../client";
import BlogLoader from "../../Components/BlogLoader";
import Footer from "../../Components/Footer";
import InfoBanner from "../../Components/InfoBanner";
import DownloadModal from "../../Components/modal/DownloadModal";
import NavBar from "../../Components/NavBar";

const SingleBlog = () => {
  const { slug } = useParams();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const [posts, setPosts] = useState([]);
  const [singlePost, setSinglePost] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    setIsLoading(true);
    client
      .fetch(
        `*[_type == "post"] {
        title,
        body, 
        slug,
        _createdAt,
        mainImage {
          asset -> {
            _id,
            url,
          },
          alt
        },
        "authorName": author -> name, 
        excerpt,
      }`
      )
      .then((data) => {
        setPosts(data);
        setSinglePost(data.filter((post) => post.slug.current === slug)[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, [slug]);
  return (
    <>
      <main className="container-fluid px-0 blog">
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <section className="container-fluid">
          {isLoading ? (
            <BlogLoader height="60vh" />
          ) : (
            <div className="container single-blog">
              <div className="mb-lg-3 mb-3 d-flex gap-1 align-items-center">
                <FaArrowLeft />
                <Link to="/blog" style={{ fontSize: "16px", color: "#000" }}>
                  Back
                </Link>
              </div>

              <div className="single-blog col-lg-10 mx-auto">
                <div className="single-blog-head">
                  <p className="mb-4" style={{ fontSize: "15px" }}>
                    <span className="fw-bold">Education &nbsp;</span>{" "}
                    <span style={{}}>
                      {new Date(singlePost?._createdAt).toLocaleString("en-us", {
                        hour12: true,
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </p>
                  <h3 className="blog-title fw-bold">{singlePost?.title}</h3>
                  {singlePost?.authorName && (
                    <div className="d-flex gap-2 align-items-center">
                      <span
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: "1px solid #ccc",
                        }}
                      >
                        {/* <img
                          src={
                            singlePost.author?.mainImage?.asset.url ||
                            "https://res.cloudinary.com/oluwatoyinah/image/upload/v1680933893/a1tutor/businessman-avatar-cartoon-character-profile_18591-50583_fjzftl.avif"
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            border: "1px solid #ccc",
                          }}
                          alt="Author"
                        /> */}
                      </span>
                      <p className="author-name mb-0">{singlePost?.authorName || "A1tutor Admin"}</p>
                    </div>
                  )}
                  <div className="mt-3">
                    <img
                      src={
                        singlePost?.mainImage?.asset.url ||
                        "https://res.cloudinary.com/oluwatoyinah/image/upload/v1680933401/a1tutor/boilerplate_qxpyft.avif"
                      }
                      className="banner-img"
                      alt=""
                    />
                  </div>
                </div>

                <div className="single-blog-body my-5">
                  <div>
                    {!singlePost.body ? (
                      <p>No Content here</p>
                    ) : (
                      <SanityBlockContent blocks={singlePost.body} projectId="1buw7tjm" dataset="production" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <Footer />
      </main>
      {openDownloadModal && <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />}
    </>
  );
};

export default SingleBlog;
