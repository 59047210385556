import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useData from "../hooks/useData";

const RequireAuth = () => {
  const { auth } = useData();
  const location = useLocation();
  return auth?.access_token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
