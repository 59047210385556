import React, { useState } from "react";
import Footer from "../Components/Footer";
import InfoBanner from "../Components/InfoBanner";
import DownloadModal from "../Components/modal/DownloadModal";
import NavBar from "../Components/NavBar";
import IntroTitle from "../Components/shared/IntroTitle";

const PrivacyPolicy = () => {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  return (
    <>
      <main className="container-fluid px-0 privacy-policy">
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <section className="container-fluid">
          <div className="container privacy-policy-content">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div className="privacy-policy-content-head">
                  <IntroTitle>
                    {" "}
                    <span className="">Privacy</span>{" "}
                    <span className="tx-blue">Policy</span>
                  </IntroTitle>
                </div>

                <div className="privacy-policy-content-body">
                  <p className="mb-4">
                    Welcome to the AoneTutor Limited. By using the Service, you
                    agree, without limitation or qualification, to be bound by
                    this Privacy Policy. This policy may be updated from time to
                    time, so please check back here periodically.
                  </p>
                  <div className="mb-5">
                    <h3 className="tx-blue">What information is collected?</h3>
                    <p>
                      When opening an account, our website and or application
                      collects your data because it may be necessary to contact
                      you about your account which asks you to create a username
                      and password. The Service uses your username for
                      identification purposes only, and uses your password for
                      verification of your identity only. If you are placing an
                      order, the Service collects your credit card number and/or
                      other billing information. This information is used only
                      to collect payment for services rendered. If you are
                      placing an order or logging in to your customer account,
                      the Service may log your IP address as a security measure
                      The Service does not share your information with any
                      external third parties except when absolutely necessary,
                      as listed here in this Privacy Policy, or when authorized
                      by you
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="tx-blue">
                      What Emails and postal mails will you send me?
                    </h3>
                    <p>
                      We send emails updating you on the status of your order.
                      We may email you a renewal notice if a service is about to
                      expire. We may email you about a matter directly
                      concerning your account. We may email you if we update a
                      policy that affects your account or promotional emails.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="tx-blue">Do you use "cookies"?</h3>
                    <p>
                      The Service uses cookies. A cookie is a piece of data
                      stored on the user's computer tied to information about
                      the user. We use cookies to remember where you are in our
                      web site, and to collect anonymous traffic data in order
                      to help us improve our web site. For example, by setting a
                      cookie when you sign into your account, we know that you
                      are an authorized user. If you reject or disable the
                      cookie, you will not be able to enter the account area of
                      the site, and some pages on our web site may not work
                      properly.
                    </p>
                    <p>
                      The privacy of your personal information is afforded the
                      highest level of importance by Aonetutor Ltd. We are bound
                      by the Privacy Act of Nigeria regarding the manner in
                      which we handle your personal information and how we
                      respond to your requests to access and correct it. While
                      we take careful measurements to protect the
                      confidentiality of information provided to us, we have
                      adopted the Privacy Policy detailed in this document in
                      compliance with the Act. This document sets out our
                      information handling procedures and the rights and
                      obligations that both you and we have in relation to your
                      personal information.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="tx-blue">Collection of Information</h3>
                    <p>
                      We will only collect personal information where it is
                      reasonably necessary to do so for the conduct of our
                      business. Any collection of personal information by us
                      will be fair and lawful and will not be intrusive. Upon
                      request you will be told the identity of the collecting
                      entity, the purpose of the collection, details on how you
                      can gain access to your personal information and any
                      consequences of failing to give the information.
                    </p>
                    <p>
                      if it is reasonable and practical do so, we will collect
                      personal information about you only from you. In the
                      course of operating the business, however, it may be
                      necessary to collect personal information from various
                      external sources, including government agencies and other
                      third parties.
                    </p>

                    <p>
                      If we collect personal information about you from a third
                      party we will, where appropriate, request that the third
                      party inform you that we are holding such information, how
                      we will use and disclose it, and that you may contact us
                      to gain access to and correct and update the information.
                      We will not, however, make any such request to any third
                      party in circumstances where it would not be practical to
                      do so.
                    </p>

                    <p>
                      A number of laws require the provision of personal
                      information to third parties. The precise information
                      required to be provided will vary depending on the
                      circumstances requiring disclosure of that information.
                    </p>

                    <p>
                      We will, wherever possible, keep all personal information
                      strictly confidential. Any disclosure that is required to
                      be made to any third party will be made primarily for the
                      purpose of providing or offering goods and services to
                      you.
                    </p>

                    <p>
                      Any personal information submitted via our online form may
                      need to be processed by a third party. By submitting
                      personal information via an online form, you consent to
                      the disclosure of that information to a third party, which
                      may be located overseas, for the sole purpose of
                      processing the online form.
                    </p>

                    <p>
                      We may also use or disclose personal information about you
                      to avoid, lessen or prevent a serious emergency or crime.
                      If we use or disclose personal information about you in
                      those circumstances we will make a written record of such
                      use or disclosure.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="tx-blue">Information Security</h3>
                    <p>
                      We will take reasonable steps to hold all hard copy and
                      electronic records of your information in a secure manner
                      to ensure that they are protected from unauthorized
                      access, modification or disclosure. We will delete your
                      personal information once it is no longer needed or
                      required to be kept by law.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="tx-blue">
                      Transfer of Information Overseas
                    </h3>
                    <p>
                      By submitting personal information to AoneTutor Ltd., you
                      consent to the transfer of that information to any
                      jurisdiction in which AoneTutor Ltd. conducts its business
                      activities, for the purpose of enabling AoneTutor Ltd. to
                      provide services to you.
                    </p>

                    <p>
                      We will otherwise only transfer your personal information
                      overseas if the transfer is to you or to one of your
                      authorized representatives, with your expressed consent or
                      otherwise in accordance with the National Privacy
                      Principles. We will not send your information outside of
                      Nigeria in any other circumstances.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default PrivacyPolicy;
