import React, { useEffect, useState } from "react";

import Earth from "../assets/icons/earth.png";
import Footer from "../Components/Footer";
import InfoBanner from "../Components/InfoBanner";
import DownloadModal from "../Components/modal/DownloadModal";
import NavBar from "../Components/NavBar";
import Team from "../Components/Team";
import ValueProposition from "../Components/ValueProposition";

const About = () => {
  const [downloadLink, setDownloadLink] = useState("");
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.match(/Android/i)) {
      // Device is an Android
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // Device is an iPhone, iPad, or iPod
      setDownloadLink("https://apps.apple.com/ng/app/a1tutor/id1595970443");
    } else if (window.navigator.userAgent.match(/IEMobile/i)) {
      // Device is a Windows Phone
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    } else {
      // Device is a desktop or laptop
      setDownloadLink(
        "https://play.google.com/store/apps/details?id=com.A1Tutor.a1_app"
      );
    }
  }, []);

  return (
    <>
      <main className="container-fluid px-0">
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <section className="about-header-section container-fluid py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 col-xl-10">
                <h2 className="about-header-main-title">
                  Our mission is to{" "}
                  <span
                    className="tx-blue text-decoration-underline"
                    style={{ position: "relative", letterSpacing: 0 }}
                  >
                    promote smart learning
                  </span>{" "}
                  in Africa by taking education directly to the learners.
                </h2>
              </div>

              <div className="col-12">
                <h6>
                  A1Tutor is an innovative Educational Technology (Edtech)
                  company that is committed to providing top-quality,
                  personalized educational content to learners across Africa.
                  Our African-focused company has a strong presence in the
                  Nigerian educational sector, with rapid expansion to other
                  regions of West-Africa and the continent as a whole.
                </h6>
                <h6>
                  {" "}
                  We leverage technology to offer a student-centered approach to
                  learning, through tailored educational content that is easily
                  accessible in various formats and across multiple platforms.
                  Our comprehensive offerings help learners to study smartly and
                  compete globally .
                </h6>

                <h6>
                  At A1Tutor, our focus is on delivering the highest quality
                  education to learners in Africa, and making it accessible to
                  as many students as possible. We are passionate about
                  fostering an environment where learning is engaging,
                  effective, and enjoyable. We are committed to innovation,
                  student success, and the advancement of smart learning in
                  Africa and beyond.
                </h6>
              </div>
            </div>
          </div>

          <img src={Earth} className="img-two" alt="" />
        </section>

        <ValueProposition />
        <Team />

        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default About;
