import React from 'react'
import { motion } from 'framer-motion' 


const sectionVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      transition: { ease: "easeOut", duration: 0.5 },
    },
};

const titleVariant = {
    hidden: {
      y: 50,
    },
    visible: {
      y: 0,
    },
    leave: {
      y: 50,
      transition: { ease: "easeOut", duration: 0.5 },
    },
};



const Section5 = () => {
  return (  
    <motion.div variants={sectionVariant}
        key="one"
        exit="leave"
        initial="hidden"
        animate="visible" 
        transition={{ ease: "easeOut", duration: 0.5 }} className="mb-5 pe-lg-5 text1  py-lg-2 py-xxl-5">
            <motion.h5 
                variants={titleVariant}
                transition={{ ease: "easeOut", duration: 0.5 }} className='mb-3'>
                    Quiz & Performance Analysis
            </motion.h5>
            <motion.p   
                variants={titleVariant}
                transition={{ ease: "easeOut", duration: 0.5 }} className='col-lg-9'>
                    Assess your knowledge with our topic-related quizzes at the end of each lesson. Consolidate your strengths and get tips for necessary improvement.
            </motion.p>
    </motion.div>
  )
}

export default Section5