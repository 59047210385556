import React from "react";
import SearchIcon from "../../../assets/icons/dashboard/search-icon.svg";

const SearchInput = () => {
  return (
    <div className="dashboard-search">
      <input type="text" className="dashboard-search-input" />
      <img src={SearchIcon} alt="" />
    </div>
  );
};

export default SearchInput;
