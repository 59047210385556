import React from 'react'
import Marquee from "react-fast-marquee";
import BubbleSection from './BubbleSection';
import TestimonialCard from './TestimonialCard'
import MayowaDamilola from '../assets/images/testimonials/mayowa-damilola.webp'
import BusayoBabayemisi from '../assets/images/testimonials/busayo-babayemisi.webp'
import EMEREONYEKENECHUKWU from '../assets/images/testimonials/EMEREONYE-KENECHUKWU.webp'
import OnomeRuth from '../assets/images/testimonials/Onome-Ruth.webp'
import OshajiOluwaseun from '../assets/images/testimonials/oshaji-oluwaseun.webp'
import AmadiEmmanuel from '../assets/images/testimonials/Amadi-Emmanuel.webp'
import AranwaoluOyediran from '../assets/images/testimonials/Aranwaolu-Oyediran.webp'
import AdeolaElegbede from '../assets/images/testimonials/Adeola-elegbede.webp'
import AlexOricha from '../assets/images/testimonials/Alex-Oricha.webp'
import CollinsChidex from '../assets/images/testimonials/Collins-Chidex.webp'

const TestimonialData = [
  {
    image : AdeolaElegbede,
    name: "Adeola Elegbede",
    review: "Registered with 3 minutes. Very smooth and interesting learning features"
  },
  {
    image : AlexOricha,
    name: "Alex Oricha",
    review: "In fact if I have the chance to give this, one million Star I will do that. God bless you all who are involved for this great idea"
  },
  {
    image : MayowaDamilola,
    name: "Mayowa Damilola",
    review: "One of the best educational app, this app helps me a lot in my UTME examination. Keep up the good work 👍"
  }, 
  {
    image : BusayoBabayemisi,
    name: "Busayo Babayemisi",
    review: "An excellent app, good job 👏"
  },
  {
    image : EMEREONYEKENECHUKWU,
    name: "EMEREONYE KENECHUKWU",
    review: "Best tutor app gives what want and need even has cheap monthly plan and free videos"
  },
  {
    image : OnomeRuth,
    name: "Onome Ruth",
    review: "An absolutely amazing learning platform. The lesson videos are very explanatory and has made learning really easy for my younger sister. Kudos to the team!"
  },
  {
    image : OshajiOluwaseun,
    name: "Oshaji Oluwaseun",
    review: "The A1 tutor app is such a helpful app. It really comes handy at a time like this and of course it is my go to app any day anytime. I will gladly recommend to students and learners alike."
  },
  {
    image : AmadiEmmanuel,
    name: "Amadi Emmanuel",
    review: "The app is so easy to use, good visuals and it really did meet my expectations. If you intend to learn and at your own pace, then this is the app for you."
  },
  {
    image : AranwaoluOyediran,
    name: "Aranwaolu Oyediran",
    review: "Awesome! This app really makes learning easier for a secondary school student."
  },
  {
    image : CollinsChidex,
    name: "Collins Chidex",
    review: "This app deserves more than a six star rating"
  },
]

const Testimonials = () => {
  return (
    <section className="container-fluid py-5 testimonials">
    <BubbleSection />
        <div className="row">
            <div className="col-md-6 text-center mx-auto mb-5">
                <div className='testimonials-header'>
                    <h3 data-aos="fade-up"><span className="tx-blue">See what</span> parents & students <br className='' /> <span className="tx-blue">say about us</span></h3>
                </div>
            </div>
            <div className="col-12">
            <Marquee
              speed={25}
              loop={0}
              gradient={false}> 
              {
                TestimonialData.map((Testimonial, i) => (<TestimonialCard key={i} testimonial={Testimonial} />))
              } 
            </Marquee>
            </div>
        </div>
    </section>
  )
}

export default Testimonials