import React from "react";
import LocationIcon from "../assets/icons/location.png";
import MessageIcon from "../assets/icons/message.png";
import CallIcon from "../assets/icons/call.png";
import WhiteArrow from "../assets/images/button-arrow.png";

const Contact = () => {
  return (
    <section className="container-fluid py-5 contact-section">
      <div
        className="text-center contact-section-header pb-5"
        extratext="Contact us"
      >
        <h3 data-aos="fade-up">Contact us</h3>
      </div>
      <div className="row gap-5 gap-lg-0">
        <div className="col-lg-6 contact-section-info d-flex align-items-center justify-content-center order-1 order-lg-0">
          <div className="py-5">
            <div className="d-flex gap-4 gap-lg-5 mb-5 align-items-center">
              <img src={LocationIcon} alt="" />
              <div>
                <p>8 Abdulrasaq Street, GRA, Ilorin, Nigeria.</p>
                <p className="mb-0">
                  28-30 Sea Rd, Boscombe, Bournermouth BH5 1DF.
                </p>
              </div>
            </div>

            <div className="d-flex gap-4 gap-lg-5 mb-5 align-items-center">
              <img src={MessageIcon} alt="" />
              <p className="mb-0">contact@a1tutor.org</p>
            </div>

            <div className="d-flex gap-4 gap-lg-5 mb-5 align-items-center">
              <img src={CallIcon} alt="" />
              {/* <p className="mb-0">07771030909</p> */}
              {/* <p className="mb-0">+234 912 612 5331</p> */}
              <p className="mb-0">0912 770 8307</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 contact-section-form order-0 order-lg-1">
          <form>
            <div className="mb-4">
              <input type="text" name="" placeholder="Full name" id="" />
            </div>

            <div className="mb-4">
              <input type="email" name="" id="" placeholder="Email address" />
            </div>

            <div className="mb-4">
              <textarea
                name=""
                placeholder="Message"
                id=""
                cols="30"
                rows="4"
              ></textarea>
            </div>

            <div>
              <button
                className="purple-btn justify-content-center d-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <span>Send Message</span>
                <span>
                  <img src={WhiteArrow} alt="" style={{ width: "14px" }} />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
