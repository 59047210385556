import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import InfoBanner from "../../Components/InfoBanner";
import NavBar from "../../Components/NavBar";
import IntroTitle from "../../Components/shared/IntroTitle";
import client from "../../client";
import SanityBlockContent from "@sanity/block-content-to-react";
import { Link } from "react-router-dom";
import BlogLoader from "../../Components/BlogLoader";
import DownloadModal from "../../Components/modal/DownloadModal";

const AllBlogs = () => {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    client
      .fetch(
        `*[_type == "post"] {
    title,
    body, 
    slug,
    _createdAt,
    mainImage {
      asset -> {
        _id,
        url,
      },
      alt
    },
    "authorName": author -> name, 
    excerpt,
  }`
      )
      .then((data) => {
        setAllPosts(data);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <main className="container-fluid px-0 blog">
        <InfoBanner />

        <NavBar setOpenDownloadModal={setOpenDownloadModal} />
        <section className="container-fluid">
          <div className="container blog-content">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-content-head">
                  <IntroTitle>
                    <span className="">Our</span>{" "}
                    <span className="tx-blue">Blog</span>
                  </IntroTitle>
                  <p
                    data-aos="fade-up"
                    className="col-md-9 col-11 mx-auto col-lg-12 mx-lg-0 text-center"
                  >
                    Updates from Team A1tutor{" "}
                  </p>
                </div>

                {isLoading ? (
                  <BlogLoader height="30vh" />
                ) : (
                  <div className="blog-content-body">
                    <div className="col-lg-10 mx-auto">
                      {allPosts
                        .sort(
                          (a, b) =>
                            new Date(b._createdAt) - new Date(a._createdAt)
                        )
                        .map((eachPost) => (
                          <div className="mb-3 pb-5" key={eachPost._createdAt}>
                            <div className="row g-3 gx-xl-4 d-flex align-items-center">
                              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-8 mx-auto col-11 mt-0 mb-2 mb-lg-0">
                                {eachPost.mainImage &&
                                  eachPost.mainImage.asset && (
                                    <img
                                      src={eachPost?.mainImage?.asset.url}
                                      style={{
                                        width: "100%",
                                        border: "1px solid #ccc",
                                      }}
                                      alt=""
                                    />
                                  )}
                                {(!eachPost.mainImage ||
                                  !eachPost.mainImage.asset) && (
                                  <img
                                    src="https://res.cloudinary.com/oluwatoyinah/image/upload/v1680933401/a1tutor/boilerplate_qxpyft.avif"
                                    alt=""
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </div>
                              <div className="col-lg-8 col-xl-9 col-md-6 col-sm-8 mx-auto col-11 mt-0">
                                <Link
                                  to={`${eachPost?.slug.current}`}
                                  style={{
                                    color: "#00001a",
                                  }}
                                >
                                  <h3>{eachPost.title}</h3>
                                </Link>
                                <div className="mb-0">
                                  {eachPost.excerpt ? (
                                    <SanityBlockContent
                                      blocks={eachPost.excerpt}
                                      projectId="i9ym2tp3"
                                      dataset="production"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 col-11 mx-auto text-end">
                                <p>
                                  <span className="fw-bold">
                                    Published on: &nbsp;
                                  </span>{" "}
                                  <span>
                                    {new Date(
                                      eachPost?._createdAt
                                    ).toLocaleString("en-us", {
                                      hour12: true,
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      {openDownloadModal && (
        <DownloadModal setOpenDownloadModal={setOpenDownloadModal} />
      )}
    </>
  );
};

export default AllBlogs;
