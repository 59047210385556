import React from "react";

const ValueProposition = () => {
  return (
    <section className="container-fluid py-5 value__section">
      <div className="container">
        <div className="value__section-header mb-5">
          <h3>
            Our<span className="tx-blue"> Value Proposition</span>
          </h3>
        </div>

        <div className="col-lg-12 mx-auto">
          <div className="row g-3">
            <div className="col-xl-3 col-lg-11 mx-auto">
              <div className="value-box h-100">
                <div className="text-center">
                  {/* <h2>Values</h2> */}
                  <p>
                    We provide an easy to use learning platform that makes
                    education accessible to learners in Secondary School and
                    underserved communities. We prioritize high-quality learning
                    and foster an enjoyable learning experience, making
                    education accessible to all.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-11 mx-auto">
              <div className="row g-3">
                <div className="col-lg-5 col-md-6">
                  <div className="value-box four">
                    <div>
                      <h3>Accessibility</h3>
                      <p>
                        We believe that quality education should be accessible
                        to everyone, regardless of their background or location.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-6">
                  <div className="value-box one">
                    <div>
                      <h3>Fun</h3>
                      <p>
                        We believe that learning should be enjoyable, and we
                        strive to incorporate fun and engaging elements into our
                        platform to make learning more exciting and effective.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="value-box two">
                    <div>
                      {" "}
                      <h3>Empowerment</h3>
                      <p>
                        We believe in empowering learners to take ownership of
                        their education and become lifelong learners.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="value-box three ">
                    <div>
                      <h3>Innovation</h3>
                      <p>
                        We strive to be at the forefront of educational
                        technology, using the latest advancements to enhance the
                        learning experience.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="value-box five">
                    <div>
                      <h3>Collaboration</h3>
                      <p>
                        We work collaboratively with educators and learners to
                        create a supportive and inclusive learning environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueProposition;
