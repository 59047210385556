import React from "react";
import { Link, Outlet } from "react-router-dom";
import AuthNav from "../../Components/AuthNav";

const AuthLayout = () => {
  return (
    <main className="container-fluid px-0 register">
      <AuthNav />
      <div className="row">
        <div className="col-lg-6 register-content">
          <div>
            <div className="mb-3 register-content-img">
              <img
                src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672127854/A1tutor%20website/register-phone_mym8gi.png"
                className="mx-auto"
                alt=""
              />
            </div>

            <div>
              <h1>
                Get the <span>best grades</span> <br className="" /> in all your
                exams
              </h1>
              <h6>
                <span className="fw-bold">•</span> WAEC &nbsp;
                <span className="fw-bold">•</span> JAMB &nbsp;
                <span className="fw-bold">•</span> BECE &nbsp;
                <span className="fw-bold">•</span> A-LEVELS &nbsp;
                <span className="fw-bold">•</span> IGSCE
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-6 register-form py-5">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthLayout;
